import type {
  GetAllLanguagesForGumLanguagesSelectQuery,
  GetAllLanguagesForGumLanguagesSelectQueryVariables
} from './getAllLanguages.gql.generated'
import { useQuery } from '@vue/apollo-composable'
import gql from 'graphql-tag'
import { computed } from 'vue'

const FETCH_SIZE = 500

const query = gql`
  query GetAllLanguagesForGumLanguagesSelect($items: Int) {
    languages(pagination: { items: $items }, sort: { field: NAME, direction: ASC }) {
      collection {
        value: id
        label: name
      }
      meta {
        pages
      }
    }
  }
`

export const getAllLanguages = () => {
  const { loading, result } = useQuery<
    GetAllLanguagesForGumLanguagesSelectQuery,
    GetAllLanguagesForGumLanguagesSelectQueryVariables
  >(query, { items: FETCH_SIZE })

  const languages = computed(() => result.value?.languages.collection || [])

  return { loading, languages }
}
