import type {
  GetAllEventLodgesQuery,
  GetAllEventLodgesQueryVariables
} from './getAllEventLodges.gql.generated'
import { useQuery } from '@vue/apollo-composable'
import gql from 'graphql-tag'
import { computed } from 'vue'

const query = gql`
  query GetAllEventLodges($eventId: ID!) {
    event(id: $eventId) {
      id
      lodges {
        id
        name
      }
    }
  }
`

export const getAlLEventLodges = (eventId: string) => {
  const { result, loading } = useQuery<GetAllEventLodgesQuery, GetAllEventLodgesQueryVariables>(
    query,
    { eventId }
  )

  const lodges = computed(() => result.value?.event?.lodges || [])

  return { loading, lodges }
}
