import { ref } from 'vue'
import { SavingStatus } from '.'
import type { MutationResponse } from './types'
import type { Errors } from '@/types/errors'
import type { DoneCallback } from '@/components/ui/batch-actions/GumBatchModalAction.vue'

type UseActionArguments<T = any, K = any> = {
  action: (payload: T) => Promise<MutationResponse & K>
  onSuccess?: (result: MutationResponse & K, payload: T) => void
  onError?: (result: MutationResponse & K, payload: T) => void
  onComplete?: (result: MutationResponse & K, payload: T) => void
  successMessage?: string
  statusReport?: boolean
}

export const useAction = <T = void, K = {}>({
  action,
  onSuccess,
  onError,
  onComplete,
  successMessage,
  statusReport = true
}: UseActionArguments<T, K>) => {
  const errors = ref<Errors>([])
  const performing = ref(false)

  const perform = async (payload: T) => {
    if (statusReport) {
      SavingStatus.start()
    }
    performing.value = true

    try {
      const result = await action(payload)

      // Save any errors
      errors.value.splice(0, errors.value.length, ...(result?.errors || []))

      if (result.success) {
        onSuccess?.(result, payload)
      } else {
        onError?.(result, payload)
      }

      onComplete?.(result, payload)
      if (statusReport) {
        SavingStatus.completeViaMutationResponse(result, { successMessage })
      }
    } catch (ex) {
      console.error(ex)

      if (statusReport) {
        SavingStatus.fail('Something went wrong, please, try again later.')
      }
    } finally {
      performing.value = false
    }
  }

  return { perform, errors, performing }
}

// Version of `useAction` specially suited for use with batch actions
export const useBatchAction = <K = {}>({
  action,
  onComplete,
  ...rest
}: UseActionArguments<{ ids: string[]; done: DoneCallback }, K>) =>
  useAction({
    ...rest,
    action,
    onComplete: (result, payload) => {
      payload.done(result.success || false)

      onComplete?.(result, payload)
    }
  })
