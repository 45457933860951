import type {
  GetParticipationNameForSelectQuery,
  GetParticipationNameForSelectQueryVariables
} from './getParticipationName.gql.generated'
import { useLazyQuery } from '@vue/apollo-composable'
import gql from 'graphql-tag'
import { computed } from 'vue'

const query = gql`
  query GetParticipationNameForSelect($id: ID!) {
    eventParticipation(id: $id) {
      id
      person {
        id
        displayName
      }
    }
  }
`

export const getParticipationName = () => {
  const { result, load, loading } = useLazyQuery<
    GetParticipationNameForSelectQuery,
    GetParticipationNameForSelectQueryVariables
  >(query)

  const participation = computed(() =>
    result.value?.eventParticipation?.id
      ? {
          value: result.value.eventParticipation.id,
          label: result.value.eventParticipation.person.displayName
        }
      : null
  )

  return { load, loading, participation }
}
