import type { SearchPeopleQuery, SearchPeopleQueryVariables } from './searchPeople.gql.generated'
import { useQuery } from '@vue/apollo-composable'
import gql from 'graphql-tag'
import { computed } from 'vue'

const query = gql`
  query SearchPeople($search: String) {
    people(search: $search, pagination: { items: 20 }) {
      collection {
        value: id
        label: displayName
      }
    }
  }
`

export const searchPeople = ({ search }: { search?: string } = {}) => {
  const { result, loading, refetch } = useQuery<SearchPeopleQuery, SearchPeopleQueryVariables>(
    query,
    { search }
  )

  const people = computed(() => result.value?.people?.collection || [])

  return { people, loading, refetch }
}
