import type {
  GetAllContactKindsForGumContactKindSelectQuery,
  GetAllContactKindsForGumContactKindSelectQueryVariables
} from './getAllContactKinds.gql.generated'
import { useQuery } from '@vue/apollo-composable'
import gql from 'graphql-tag'
import { computed } from 'vue'

const query = gql`
  query GetAllContactKindsForGumContactKindSelect {
    contactKinds {
      collection {
        value: id
        label: name
        icon
      }
      meta {
        total
      }
    }
  }
`

export const getAllContactKinds = () => {
  const { loading, result } = useQuery<
    GetAllContactKindsForGumContactKindSelectQuery,
    GetAllContactKindsForGumContactKindSelectQueryVariables
  >(query)

  const contactKinds = computed(() => result.value?.contactKinds.collection || [])

  return { loading, contactKinds }
}
