import type { SearchEventsQuery, SearchEventsQueryVariables } from './searchEvents.gql.generated'
import { useQuery } from '@vue/apollo-composable'
import gql from 'graphql-tag'
import { computed } from 'vue'

const query = gql`
  query SearchEvents($search: String, $items: Int) {
    events(search: $search, pagination: { page: 1, items: $items }) {
      collection {
        value: id
        label: name
      }
    }
  }
`

export const searchEvents = ({ search }: { search?: string } = {}) => {
  const { result, loading, refetch } = useQuery<SearchEventsQuery, SearchEventsQueryVariables>(
    query,
    { search }
  )

  const events = computed(() => result.value?.events?.collection || [])

  return { events, loading, refetch }
}
