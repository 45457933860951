import type { ExportableEnum } from '@/graphql/app'
import type {
  GetExportFieldsQuery,
  GetExportFieldsQueryVariables
} from './getExportFields.gql.generated'
import { useQuery } from '@vue/apollo-composable'
import gql from 'graphql-tag'
import { computed } from 'vue'

const query = gql`
  query GetExportFields($exportable: ExportableEnum!) {
    exportableFields(exportable: $exportable) {
      id
      title
      group
    }
  }
`

export const getExportableFields = (exportable: ExportableEnum) => {
  const { loading, result } = useQuery<GetExportFieldsQuery, GetExportFieldsQueryVariables>(query, {
    exportable
  })

  const fields = computed(() => result.value?.exportableFields || [])

  return { loading, fields }
}
