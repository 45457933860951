import type {
  GetEntitiesTitlesQuery,
  GetEntitiesTitlesQueryVariables
} from './getEntitiesTitles.gql.generated'
import { useLazyQuery } from '@vue/apollo-composable'
import gql from 'graphql-tag'
import { computed } from 'vue'

const query = gql`
  query GetEntitiesTitles(
    $eventId: ID!
    $hasEventId: Boolean!
    $personId: ID!
    $hasPersonId: Boolean!
    $institutionId: ID!
    $hasInstitutionId: Boolean!
    $listId: ID!
    $hasListId: Boolean!
    $formId: ID!
    $hasFormId: Boolean!
    $submissionId: ID!
    $hasSubmissionId: Boolean!
    $emailId: ID!
    $hasEmailId: Boolean!
    $noteId: ID!
    $hasNoteId: Boolean!
    $roomId: ID!
    $hasRoomId: Boolean!
  ) {
    event(id: $eventId) @include(if: $hasEventId) {
      id
      title: name
    }

    person(id: $personId) @include(if: $hasPersonId) {
      id
      title: displayName
    }

    institution(id: $institutionId) @include(if: $hasInstitutionId) {
      id
      title: name
    }

    tagList(id: $listId) @include(if: $hasListId) {
      id
      title: name
    }

    form(id: $formId) @include(if: $hasFormId) {
      id
      title: name
    }

    formSubmission(id: $submissionId) @include(if: $hasSubmissionId) {
      id
      title: friendlyId
    }

    email(id: $emailId) @include(if: $hasEmailId) {
      id
      title: kind
    }

    releaseNote(id: $noteId) @include(if: $hasNoteId) {
      id
      title: title
    }

    room(id: $roomId) @include(if: $hasRoomId) {
      id
      title: name
    }
  }
`

type GetEntitiesTitlesArguments = {
  eventId?: string | null
  personId?: string | null
  institutionId?: string | null
  listId?: string | null
  formId?: string | null
  submissionId?: string | null
  emailId?: string | null
  noteId?: string | null
  roomId?: string | null
}

export const getEntitiesTitles = ({
  eventId,
  personId,
  institutionId,
  listId,
  formId,
  submissionId,
  emailId,
  noteId,
  roomId
}: GetEntitiesTitlesArguments) => {
  const { loading, load, result } = useLazyQuery<
    GetEntitiesTitlesQuery,
    GetEntitiesTitlesQueryVariables
  >(query, {
    eventId: eventId || '',
    hasEventId: !!eventId,
    personId: personId || '',
    hasPersonId: !!personId,
    institutionId: institutionId || '',
    hasInstitutionId: !!institutionId,
    listId: listId || '',
    hasListId: !!listId,
    formId: formId || '',
    hasFormId: !!formId,
    submissionId: submissionId || '',
    hasSubmissionId: !!submissionId,
    emailId: emailId || '',
    hasEmailId: !!emailId,
    noteId: noteId || '',
    hasNoteId: !!noteId,
    roomId: roomId || '',
    hasRoomId: !!roomId
  })

  const titles = computed(() => ({
    event: result.value?.event?.title,
    person: result.value?.person?.title,
    institution: result.value?.institution?.title,
    list: result.value?.tagList?.title,
    form: result.value?.form?.title,
    submission: result.value?.formSubmission?.title,
    email: result.value?.email?.title,
    releaseNote: result.value?.releaseNote?.title,
    room: result.value?.room?.title
  }))

  return { loading, load, titles }
}
