import type {
  GetAllEventTypesQuery,
  GetAllEventTypesQueryVariables
} from './getEventTypes.gql.generated'
import { useQuery } from '@vue/apollo-composable'
import gql from 'graphql-tag'
import { computed } from 'vue'

const query = gql`
  query GetAllEventTypes {
    eventTypes(pagination: { items: 500 }) {
      collection {
        value: id
        label: name
      }
    }
  }
`

export const getEventTypes = () => {
  const { loading, result } = useQuery<GetAllEventTypesQuery, GetAllEventTypesQueryVariables>(query)

  const eventTypes = computed(() => result.value?.eventTypes.collection || [])

  return { loading, eventTypes }
}
