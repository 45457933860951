import type {
  GetEventsNamesForSelectQuery,
  GetEventsNamesForSelectQueryVariables
} from './getEventsNames.gql.generated'
import { useLazyQuery } from '@vue/apollo-composable'
import gql from 'graphql-tag'
import { computed } from 'vue'

const query = gql`
  query GetEventsNamesForSelect($ids: [ID!]!) {
    events(ids: $ids, pagination: { items: 500 }) {
      collection {
        value: id
        label: name
      }
    }
  }
`

export const getEventsNames = () => {
  const { result, load, loading } = useLazyQuery<
    GetEventsNamesForSelectQuery,
    GetEventsNamesForSelectQueryVariables
  >(query)

  const events = computed(() => result.value?.events.collection || [])

  return { events, load, loading }
}
