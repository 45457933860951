import type {
  GetAllCategoriesQuery,
  GetAllCategoriesQueryVariables
} from './getAllInstitutionCategories.gql.generated'
import { useQuery } from '@vue/apollo-composable'
import gql from 'graphql-tag'
import { computed } from 'vue'

const query = gql`
  query GetAllCategories {
    institutionCategories {
      collection {
        value: id
        label: name
      }
    }
  }
`

export const getAllCategories = () => {
  const { loading, result } = useQuery<GetAllCategoriesQuery, GetAllCategoriesQueryVariables>(query)

  const categories = computed(() => result.value?.institutionCategories.collection || [])

  return { loading, categories }
}
