import { h } from 'vue'
import notification from 'ant-design-vue/es/notification'
import { SyncOutlined } from '@ant-design/icons-vue'
import { SuccessIcon } from '@/components/ui'
import type { MutationResponse } from './types'

window.addEventListener('beforeunload', (e) => {
  // TODO: Use better method to determine if we are processing something.
  // Relying on the presence of the toast is not a great way of doing this 🙈
  if (document.querySelector('.gum-autosave-notification.-started')) {
    e.preventDefault()
  }
})

export const start = () => {
  notification.info({
    message: 'Saving',
    description: 'We are saving your changes',
    key: 'autosave',
    icon: h(SyncOutlined, { spin: true }),
    duration: 100000,
    placement: 'bottomRight',
    class: 'gum-notification gum-autosave-notification -started'
  })
}

export const complete = ({
  successMessage = 'Your changes were saved successfully'
}: { successMessage?: string } = {}) => {
  notification.success({
    message: 'Saved',
    description: successMessage,
    key: 'autosave',
    icon: h(SuccessIcon),
    duration: 5,
    placement: 'bottomRight',
    class: 'gum-notification gum-autosave-notification'
  })
}

export const fail = (description: string) => {
  notification.error({
    message: 'Failed',
    description,
    key: 'autosave',
    duration: 5,
    placement: 'bottomRight',
    class: 'gum-notification gum-autosave-notification'
  })
}

export const completeViaMutationResponse = (
  lastResult: MutationResponse | null,
  { successMessage }: { successMessage?: string } = {}
) => {
  if (!lastResult || (!lastResult.success && lastResult.errors && !lastResult.errors?.length)) {
    return fail('Something went wrong, please, try again later.')
  }

  const { success, errors } = lastResult

  if (success) {
    complete({ successMessage })
  } else if (errors && errors.length) {
    if (errors.length === 1 && errors[0].path === 'base') {
      fail(errors[0].messages.join('. '))
    } else {
      fail('Some of the fields are invalid.')
    }
    console.error(errors)
  } else {
    fail('Something went wrong, please, try again later.')
  }
}
