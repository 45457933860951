import type {
  GetEventTransportLocationsSelectQuery,
  GetEventTransportLocationsSelectQueryVariables
} from './getEventTransportLocationsSelect.gql.generated'
import { useQuery } from '@vue/apollo-composable'
import gql from 'graphql-tag'
import { computed } from 'vue'

const query = gql`
  query GetEventTransportLocationsSelect($id: ID!) {
    event(id: $id) {
      id
      transportLocations {
        id
        name
      }
    }
  }
`

export type TransportLocation = NonNullable<
  NonNullable<GetEventTransportLocationsSelectQuery>['event']
>['transportLocations'][0]

export const getEventTransportLocations = (id: string) => {
  const { result, loading, refetch } = useQuery<
    GetEventTransportLocationsSelectQuery,
    GetEventTransportLocationsSelectQueryVariables
  >(query, { id })

  const transportLocations = computed(() => result.value?.event?.transportLocations || [])

  return { transportLocations, loading, refetch }
}
