<template>
  <Button
    :size="props.size"
    :danger="props.danger"
    :type="props.type"
    :loading="props.loading"
    :block="props.block"
    :disabled="props.disabled"
    :icon="props.icon"
    :shape="props.shape"
    :href="props.href"
    @click="handleClick($event)"
    class="gum-button"
    :class="{ 'gum-button-narrow': narrow }"
  >
    <template #icon><slot name="icon" /></template>
    <slot />
  </Button>
</template>

<script setup lang="ts">
import { Button, type ButtonProps } from 'ant-design-vue/es/components'
import { inject } from 'vue'
import { useRouter, type RouteLocationRaw, routerKey, type Router } from 'vue-router'

const fakeRouter = {} as Router
const injected = inject(routerKey, fakeRouter)
const isRouterAvailable = injected !== fakeRouter

const router = isRouterAvailable ? useRouter() : fakeRouter

// Props
export interface Props {
  loading?: boolean
  type?: 'primary' | 'default' | 'dashed' | 'link'
  danger?: boolean
  size?: ButtonProps['size']
  block?: boolean
  disabled?: boolean
  icon?: ButtonProps['icon']
  shape?: ButtonProps['shape']
  href?: ButtonProps['href']
  routeTo?: RouteLocationRaw
  narrow?: boolean
}

const props = withDefaults(defineProps<Props>(), {
  loading: false,
  type: 'primary',
  danger: false,
  size: 'large',
  block: false,
  disabled: false,
  shape: 'round',
  narrow: false
})

// Events
const emit = defineEmits<{
  (e: 'click', event: MouseEvent): void
}>()

// Slots
defineSlots<{
  default: any
  icon: any
}>()

// Handles
const handleClick = (event: MouseEvent) => {
  if (props.routeTo) {
    if (!isRouterAvailable) {
      throw new Error('Router is not available')
    }

    router.push(props.routeTo)
  }

  emit('click', event)
}
</script>

<style lang="less">
a.gum-button.ant-btn {
  padding-top: 6.4px !important;
}

.gum-button {
  @apply inline-flex items-center justify-center px-4 py-0;
  color: #0958d9;

  svg + span,
  span + svg {
    margin-left: 0.5rem;
  }

  &:not(.ant-btn-link) {
    box-shadow: 0px 2px 0px 0px #00000005;
  }

  &.gum-button-narrow {
    @apply px-2;

    &.ant-btn-sm {
      @apply px-1;
    }

    &.ant-btn-lg {
      @apply px-3;
    }
  }

  &.ant-btn-block {
    @apply justify-center;
  }

  &.ant-btn-round {
    border-radius: 6px;

    &.ant-btn-sm {
      border-radius: 4px;
    }

    &.ant-btn-lg {
      border-radius: 8px;
    }
  }

  &.ant-btn-primary {
    background: #1677ff;
    border-color: #1677ff;
    color: white;

    &:focus,
    &:hover {
      &:not([disabled]):not(.ant-btn-dangerous) {
        border-color: #4096ff;
        background: #4096ff;
      }
    }

    &[disabled] {
      @apply text-opacity-25 text-black;
    }
  }

  &.ant-btn-dangerous {
    &.ant-btn-primary {
      background: #fff1f0;
      border-color: #fff1f0;
      color: #cf1322;

      &:hover,
      &:focus {
        &:not([disabled]) {
          border-color: #cf1322;
          background: #fff1f0;
        }
      }
    }

    &.ant-btn-default {
      color: #cf1322;
      border-color: #d9d9d9;

      &:hover,
      &:focus {
        &:not([disabled]) {
          border-color: #cf1322;
          background: #fff1f0;
        }
      }
    }
  }

  &.ant-btn-loading {
    &.ant-btn-dashed {
      color: #0958d9 !important;
      border-color: #d9d9d9 !important;
    }

    &.ant-btn-primary:not(.ant-btn-dangerous) {
      background-color: #4096ff !important;
    }

    &.ant-btn-default {
      color: #0958d9 !important;
      border-color: #d9d9d9 !important;
    }

    &.ant-btn-dangerous {
      color: #cf1322 !important;
      border-color: #fff1f0 !important;
    }

    &.ant-btn-link {
      color: #0958d9 !important;
    }
  }

  &[disabled] {
    color: rgba(0, 0, 0, 0.25) !important;

    &:not(.ant-btn-link) {
      background: #f5f5f5 !important;
      border-color: #d9d9d9 !important;
    }
  }
}
</style>
