import type {
  GetAllEventGroupsQuery,
  GetAllEventGroupsQueryVariables
} from './getAllEventGroups.gql.generated'
import { useQuery } from '@vue/apollo-composable'
import gql from 'graphql-tag'
import { computed } from 'vue'

const query = gql`
  query GetAllEventGroups($eventId: ID!) {
    event(id: $eventId) {
      id
      groups {
        value: id
        label: name
      }
    }
  }
`

export const getAllEventGroups = (eventId: string) => {
  const { loading, result } = useQuery<GetAllEventGroupsQuery, GetAllEventGroupsQueryVariables>(
    query,
    { eventId }
  )

  const groups = computed(() => result.value?.event?.groups || [])

  return { loading, groups }
}
