import type {
  GetAllEventGroupsForSelectQuery,
  GetAllEventGroupsForSelectQueryVariables
} from './getAllEventGroupsForSelect.gql.generated'
import { useQuery } from '@vue/apollo-composable'
import gql from 'graphql-tag'
import { computed } from 'vue'

const query = gql`
  query GetAllEventGroupsForSelect($eventId: ID!) {
    event(id: $eventId) {
      id
      groups {
        id
        name
      }
    }
  }
`

export const getEventCapacities = (eventId: string) => {
  const { loading, result } = useQuery<
    GetAllEventGroupsForSelectQuery,
    GetAllEventGroupsForSelectQueryVariables
  >(query, { eventId })

  const groups = computed(() => result.value?.event?.groups || [])

  return { loading, groups }
}
