import type {
  GetAllVisibleEventLodgesQuery,
  GetAllVisibleEventLodgesQueryVariables
} from './getAllVisibleEventLodges.gql.generated'
import { useQuery } from '@vue/apollo-composable'
import gql from 'graphql-tag'
import { computed } from 'vue'

const query = gql`
  query GetAllVisibleEventLodges($eventId: ID!) {
    event(id: $eventId) {
      id
      lodges {
        id
        name
        visibleInForms
      }
    }
  }
`

export const getAllVisibleEventLodges = (eventId: string) => {
  const { result, loading } = useQuery<
    GetAllVisibleEventLodgesQuery,
    GetAllVisibleEventLodgesQueryVariables
  >(query, { eventId })

  const allLodges = computed(() => result.value?.event?.lodges || [])

  const lodges = computed(() => allLodges.value.filter((x) => x.visibleInForms))

  return { loading, lodges }
}
