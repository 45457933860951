import type {
  GetAllRoomTypesQuery,
  GetAllRoomTypesQueryVariables
} from './getAllRoomTypes.gql.generated'
import { useQuery } from '@vue/apollo-composable'
import gql from 'graphql-tag'
import { computed, watch } from 'vue'

const query = gql`
  query GetAllRoomTypes {
    roomTypes(pagination: { items: 500 }) {
      collection {
        value: id
        label: name
      }
      meta {
        total
      }
    }
  }
`

export const getAllRoomTypes = () => {
  const { result, loading } = useQuery<GetAllRoomTypesQuery, GetAllRoomTypesQueryVariables>(query)

  const roomTypes = computed(() => result.value?.roomTypes.collection || [])
  const total = computed(() => result.value?.roomTypes.meta.total)

  watch(total, () => {
    if (total.value && total.value > 500) {
      throw 'There are more than 500 room types'
    }
  })

  return { loading, roomTypes }
}
