import type {
  GetInstitutionLocationsForSelectQuery,
  GetInstitutionLocationsForSelectQueryVariables
} from './getInstitutionLocations.gql.generated'
import { useQuery } from '@vue/apollo-composable'
import gql from 'graphql-tag'
import { computed } from 'vue'

const query = gql`
  query GetInstitutionLocationsForSelect($id: ID!) {
    institution(id: $id) {
      id
      locations {
        id
        city
      }
    }
  }
`

export const getInstitutionLocations = (id: string) => {
  const { loading, result } = useQuery<
    GetInstitutionLocationsForSelectQuery,
    GetInstitutionLocationsForSelectQueryVariables
  >(query, { id })

  const locations = computed(() => result.value?.institution?.locations || [])

  return { loading, locations }
}
