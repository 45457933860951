import type {
  GetInstitutionNameForSelectQuery,
  GetInstitutionNameForSelectQueryVariables
} from './getInstitutionName.gql.generated'
import { useLazyQuery } from '@vue/apollo-composable'
import gql from 'graphql-tag'
import { computed } from 'vue'

const query = gql`
  query GetInstitutionNameForSelect($id: ID!) {
    institution(id: $id) {
      value: id
      label: name
    }
  }
`

export const getInstitutionName = () => {
  const { result, load, loading } = useLazyQuery<
    GetInstitutionNameForSelectQuery,
    GetInstitutionNameForSelectQueryVariables
  >(query)

  const institution = computed(() => result.value?.institution)

  return { load, loading, institution }
}
