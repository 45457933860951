import * as Sentry from '@sentry/vue'
import type { App } from 'vue'

export const initSentry = (app: App, { replies = false }: { replies: boolean }) => {
  const dsn = import.meta.env.VITE_SENTRY_DSN

  if (!dsn) {
    return
  }

  Sentry.init({
    app,
    dsn,
    integrations: [Sentry.replayIntegration()],
    // Performance Monitoring
    tracesSampleRate: 0, //  Capture 100% of the transactions
    // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
    tracePropagationTargets: ['localhost', /^https:\/\/yourserver\.io\/api/],
    // Session Replay
    replaysSessionSampleRate: replies ? 0.01 : 0,
    replaysOnErrorSampleRate: replies ? 1.0 : 0
  })
}
