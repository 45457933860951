import type {
  GetPersonPassportsForSelectQuery,
  GetPersonPassportsForSelectQueryVariables
} from './getPersonPassports.gql.generated'
import { useQuery } from '@vue/apollo-composable'
import gql from 'graphql-tag'
import { computed } from 'vue'

const query = gql`
  query GetPersonPassportsForSelect($id: ID!) {
    person(id: $id) {
      id
      passports {
        id
        number
        country {
          id
          name
        }
        expiresAt
      }
    }
  }
`

export const getPersonPassports = (id: string) => {
  const { loading, result } = useQuery<
    GetPersonPassportsForSelectQuery,
    GetPersonPassportsForSelectQueryVariables
  >(query, { id })

  const passports = computed(() => result.value?.person?.passports || [])

  return { loading, passports }
}
