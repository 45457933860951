import type {
  GetAllFilterEventCapacitiesQuery,
  GetAllFilterEventCapacitiesQueryVariables
} from './getAllFilterEventCapacities.gql.generated'
import { useQuery } from '@vue/apollo-composable'
import gql from 'graphql-tag'
import { computed } from 'vue'

const query = gql`
  query GetAllFilterEventCapacities {
    capacities {
      collection {
        value: id
        label: name
      }
    }
  }
`

export const getAllFilterEventCapacities = () => {
  const { loading, result } = useQuery<
    GetAllFilterEventCapacitiesQuery,
    GetAllFilterEventCapacitiesQueryVariables
  >(query)
  const capacities = computed(() => result.value?.capacities.collection || [])

  return { loading, capacities }
}
