const STORAGE_KEY = 'token'

// Returns the authentication token
export const getToken = () => sessionStorage.getItem(STORAGE_KEY)

// Returns true if the user is authenticated
export const isAuthenticated = () => !!getToken()

// Logs out the current user
export const logout = ({ redirectUrl }: { redirectUrl?: string } = {}) => {
  sessionStorage.removeItem(STORAGE_KEY)

  window.location.href = redirectUrl ? redirectUrl : window.location.href
}

// Authenticate the user
export const authenticate = ({ force = false } = {}) => {
  const queryParams = new URLSearchParams(window.location.search)

  if (queryParams.has('token')) {
    // We have a token, let's save it in session storage
    sessionStorage.setItem(STORAGE_KEY, queryParams.get('token')!)

    // Then hard reload the page (removing the `?token` query param)
    queryParams.delete('token')

    window.location.assign(
      `${window.location.origin}${window.location.pathname}?${queryParams.toString()}`
    )
  } else if (!force && sessionStorage.getItem(STORAGE_KEY)) {
    // We have a token in session already
  } else {
    // Start a new Login
    window.location.assign(
      `${import.meta.env.VITE_APP_URL}/auth/login?callback=${window.location.href}`
    )
  }
}
