import { complete, completeViaMutationResponse, fail, start } from './status'

export { useAutosave } from './use-autosave'
export { useAction, useBatchAction } from './use-action'
export const SavingStatus = {
  start,
  complete,
  fail,
  completeViaMutationResponse
}
