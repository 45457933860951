import { useQuery } from '@vue/apollo-composable'
import gql from 'graphql-tag'
import type {
  GetEmailsForSelectQuery,
  GetEmailsForSelectQueryVariables
} from './getEmails.gql.generated'
import { computed } from 'vue'

const query = gql`
  query GetEmailsForSelect($eventId: ID!) {
    emails(eventId: $eventId) {
      id
      name
      kind
    }
  }
`

export type Email = GetEmailsForSelectQuery['emails'][0]

export const getEmailsForSelect = ({ eventId }: { eventId: string }) => {
  const { result, loading, refetch } = useQuery<
    GetEmailsForSelectQuery,
    GetEmailsForSelectQueryVariables
  >(query, { eventId })

  const emails = computed(() => result.value?.emails || [])

  return { emails, loading, refetch }
}
