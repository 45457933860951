<template>
  <div
    v-if="mode === 'development' || mode === 'staging'"
    :class="{
      'bg-[#951010]': mode === 'staging',
      'bg-[#074671]': mode === 'development'
    }"
    class="flex justify-center py-2"
  >
    <span class="text-white font-semibold capitalize">{{ mode }} environment</span>
  </div>
</template>

<script setup lang="ts">
const mode = import.meta.env.MODE
</script>
