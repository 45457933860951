import type {
  GetRoomTypesOfLodgeQuery,
  GetRoomTypesOfLodgeQueryVariables
} from './getRoomTypesOfLodge.gql.generated'
import { useQuery } from '@vue/apollo-composable'
import gql from 'graphql-tag'
import { computed } from 'vue'

const query = gql`
  query GetRoomTypesOfLodge($lodgeId: ID!) {
    eventLodge(id: $lodgeId) {
      id
      roomQuantities {
        id
        roomType {
          id
          name
        }
        sharingAllowed
      }
    }
  }
`

export const getAllRoomTypesOfLodge = (lodgeId: string) => {
  const { loading, result, refetch } = useQuery<
    GetRoomTypesOfLodgeQuery,
    GetRoomTypesOfLodgeQueryVariables
  >(query, { lodgeId })

  const rooms = computed(() => result.value?.eventLodge?.roomQuantities || [])

  return { loading, rooms, refetch }
}
