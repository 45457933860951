import type {
  GetInstitutionsNamesForSelectQuery,
  GetInstitutionsNamesForSelectQueryVariables
} from './getInstitutionsNames.gql.generated'
import { useLazyQuery } from '@vue/apollo-composable'
import gql from 'graphql-tag'
import { computed } from 'vue'

const query = gql`
  query GetInstitutionsNamesForSelect($ids: [ID!]!) {
    institutions(ids: $ids, pagination: { items: 500 }) {
      collection {
        value: id
        label: name
      }
    }
  }
`

export const getInstitutionsNames = () => {
  const { result, load, loading } = useLazyQuery<
    GetInstitutionsNamesForSelectQuery,
    GetInstitutionsNamesForSelectQueryVariables
  >(query)

  const institutions = computed(() => result.value?.institutions.collection || [])

  return { institutions, load, loading }
}
