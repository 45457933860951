import type {
  SearchInstitutionsForSelectQuery,
  SearchInstitutionsForSelectQueryVariables
} from './searchInstitutions.gql.generated'
import { useQuery } from '@vue/apollo-composable'
import gql from 'graphql-tag'
import { computed } from 'vue'

const query = gql`
  query SearchInstitutionsForSelect($search: String) {
    institutions(search: $search, pagination: { items: 20 }) {
      collection {
        value: id
        label: name
      }
    }
  }
`

export const searchInstitutions = () => {
  const { loading, result, refetch } = useQuery<
    SearchInstitutionsForSelectQuery,
    SearchInstitutionsForSelectQueryVariables
  >(query)

  const institutions = computed(() => result.value?.institutions.collection || [])

  return { loading, refetch, institutions }
}
