import type {
  GetAllEventSubEventsQuery,
  GetAllEventSubEventsQueryVariables
} from './getEventSubEvents.gql.generated'
import { useQuery } from '@vue/apollo-composable'
import gql from 'graphql-tag'
import { computed } from 'vue'

const query = gql`
  query GetAllEventSubEvents($eventId: ID!) {
    event(id: $eventId) {
      id
      subEvents {
        value: id
        label: name
      }
    }
  }
`

export const getSubEvents = (eventId: string) => {
  const { loading, result } = useQuery<
    GetAllEventSubEventsQuery,
    GetAllEventSubEventsQueryVariables
  >(query, { eventId })

  const subEvents = computed(() => result.value?.event?.subEvents || [])

  return { loading, subEvents }
}
