import { deserializeFilters } from '@/components/ui'
import type { AdvancedFilter } from '@/graphql/app'
import type { PaginationArguments } from '@/types/pagination'

const query = () => new URLSearchParams(window.location.search)

export const numberParam = (param: string, defaultValue: number): number => {
  const value = query().get(param)

  if (typeof value === 'string') {
    const numberValue = parseInt(value)

    if (!isNaN(numberValue)) {
      return numberValue
    }
  }

  return defaultValue
}

export const stringParam = (param: string, defaultValue: string = ''): string => {
  const value = query().get(param)

  if (typeof value !== 'string') {
    return defaultValue
  }

  return value || defaultValue
}

export const booleanParam = (param: string, defaultValue: boolean = false): boolean => {
  if (!query().has(param)) {
    return defaultValue
  }

  return query().get(param) === 'true'
}

// Reads pagination parameters from current URL and returns them
// Safely falls back to sensible defaults
export const currentPagination = (): PaginationArguments => {
  return {
    page: numberParam('page', 1),
    items: numberParam('items', 10)
  }
}

export const currentSearch = (): string => {
  return stringParam('search')
}

export const currentSort = <T>() => {
  const field = stringParam('sort_field')
  const direction = stringParam('sort_direction')

  if (!field || !direction) {
    return undefined
  }

  return { field, direction } as T
}

export const currentFilters = () => deserializeFilters(stringParam('filters'))

export const currentAdvancedFilters = (): AdvancedFilter[] =>
  currentFilters()
    .filter((filter) => !!filter.value)
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    .map(({ id, value, type, ...rest }) => ({
      ...rest,
      values: [value].flat()
    })) as AdvancedFilter[]

export const currentIncludeArchived = () => booleanParam('show_archived')

export const currentColumns = ({ fallback = [] }: { fallback?: string[] } = {}): string[] => {
  const columnsInUrl = query().getAll('columns[]')

  if (columnsInUrl && columnsInUrl.length > 0) {
    return columnsInUrl
  }

  return fallback
}
