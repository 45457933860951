import gql from 'graphql-tag'
import { useMutation } from '@vue/apollo-composable'
import type {
  UpdateLastVisitMutation,
  UpdateLastVisitMutationVariables
} from './updateUserLastVisit.gql.generated'

const query = gql`
  mutation UpdateLastVisit($userId: ID!, $date: ISO8601Date!) {
    updateLastVisit(input: { userId: $userId, date: $date }) {
      user {
        id
        releaseNoteLastVisit
      }
      success
      errors {
        messages
        path
      }
    }
  }
`

export const updateLastVisitMutation = () => {
  const { mutate, loading } = useMutation<
    UpdateLastVisitMutation,
    UpdateLastVisitMutationVariables
  >(query)

  const updateLastVisit = async (variables: { userId: string; date: string }) => {
    const response = await mutate(variables)

    const success = response?.data?.updateLastVisit?.success
    const errors = response?.data?.updateLastVisit?.errors
    const user = response?.data?.updateLastVisit?.user

    return { user, success, errors }
  }

  return { loading, updateLastVisit }
}
