import type {
  GetAllGendersForGumGenderSelectQuery,
  GetAllGendersForGumGenderSelectQueryVariables
} from './getAllGenders.gql.generated'
import { useQuery } from '@vue/apollo-composable'
import gql from 'graphql-tag'
import { computed } from 'vue'

const FETCH_SIZE = 500

const query = gql`
  query GetAllGendersForGumGenderSelect($items: Int) {
    genders(pagination: { items: $items }, sort: { field: NAME, direction: ASC }) {
      collection {
        value: id
        label: name
      }
      meta {
        pages
      }
    }
  }
`

export const getAllGenders = () => {
  const { loading, result } = useQuery<
    GetAllGendersForGumGenderSelectQuery,
    GetAllGendersForGumGenderSelectQueryVariables
  >(query, { items: FETCH_SIZE })

  const genders = computed(() => result.value?.genders.collection || [])

  return { loading, genders }
}
