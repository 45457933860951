import type { ChangelogModelEnum } from '@/graphql/app'
import {
  type GetLastChangelogQuery,
  type GetLastChangelogQueryVariables
} from './getLastEdit.gql.generated'
import { useQuery } from '@vue/apollo-composable'
import gql from 'graphql-tag'
import { computed } from 'vue'

const query = gql`
  query GetLastChangelog($id: ID!, $type: ChangelogModelEnum!) {
    changelog(type: $type, id: $id, pagination: { items: 1, page: 1 }) {
      collection {
        id
        who {
          id
          firstName
          lastName
        }
        createdAt
      }
    }
  }
`

export const getLastChangelog = (variables: { id: string; type: ChangelogModelEnum }) => {
  const { result, loading } = useQuery<GetLastChangelogQuery, GetLastChangelogQueryVariables>(
    query,
    variables
  )

  const changelog = computed(() => result.value?.changelog.collection?.[0] || null)

  return { loading, changelog }
}
