import type {
  GetAllTitlesForGumTitleSelectQuery,
  GetAllTitlesForGumTitleSelectQueryVariables
} from './getAllTitles.gql.generated'
import { useQuery } from '@vue/apollo-composable'
import gql from 'graphql-tag'
import { computed } from 'vue'

const FETCH_SIZE = 500

const query = gql`
  query GetAllTitlesForGumTitleSelect($ids: [ID!], $items: Int) {
    personTitles(ids: $ids, pagination: { items: $items }, sort: { field: TITLE, direction: ASC }) {
      collection {
        value: id
        label: title
      }
      meta {
        pages
      }
    }
  }
`

export const getAllTitles = ({ ids }: { ids?: string[] } = {}) => {
  const { loading, result, refetch } = useQuery<
    GetAllTitlesForGumTitleSelectQuery,
    GetAllTitlesForGumTitleSelectQueryVariables
  >(query, { ids, items: FETCH_SIZE })

  const titles = computed(() => result.value?.personTitles.collection || [])

  return { loading, titles, refetch }
}
