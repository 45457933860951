import { useQuery } from '@vue/apollo-composable'
import gql from 'graphql-tag'
import type { GetPoliciesQuery, GetPoliciesQueryVariables } from './getPolicies.gql.generated'
import { computed } from 'vue'
import { keyBy } from 'lodash-es'

const query = gql`
  query GetPolicies {
    policies {
      resource
      abilities {
        list
        edit
        view
      }
    }
  }
`

export const getPolicies = () => {
  const { loading, result } = useQuery<GetPoliciesQuery, GetPoliciesQueryVariables>(
    query,
    {},
    { fetchPolicy: 'cache-first' }
  )

  const policies = computed(() => keyBy(result.value?.policies || [], 'resource'))

  return { loading, policies }
}
