import { useMutation } from '@vue/apollo-composable'
import gql from 'graphql-tag'
import type {
  RenderEmailPreviewMutation,
  RenderEmailPreviewMutationVariables
} from './useRenderEmailPreview.gql.generated'

const query = gql`
  mutation RenderEmailPreview($input: RenderEmailPreviewMutationInput!) {
    renderEmailPreview(input: $input) {
      success
      errors {
        messages
        path
      }
      preview {
        to
        cc
        bcc
        subject
        body
      }
    }
  }
`

export type EmailPreview = NonNullable<RenderEmailPreviewMutation['renderEmailPreview']>['preview']

export const useRenderEmailPreview = () => {
  const { loading, mutate } = useMutation<
    RenderEmailPreviewMutation,
    RenderEmailPreviewMutationVariables
  >(query)

  const renderEmailPreview = async (input: RenderEmailPreviewMutationVariables['input']) => {
    const response = await mutate({ input })

    return {
      success: response?.data?.renderEmailPreview?.success,
      errors: response?.data?.renderEmailPreview?.errors,
      preview: response?.data?.renderEmailPreview?.preview
    }
  }

  return { loading, renderEmailPreview }
}
