import { SuccessIcon } from '@/components/ui'
import notification, { type NotificationArgsProps } from 'ant-design-vue/es/notification'
import { h } from 'vue'

const commonOptions: Partial<NotificationArgsProps> = {
  placement: 'bottomRight',
  class: 'gum-notification',
  duration: 5 // Seconds
}

export const notifySuccess = ({
  message = 'Success',
  description
}: {
  message?: string
  description: string
}) => {
  notification.open({
    ...commonOptions,
    message,
    description,
    icon: h(SuccessIcon)
  })
}

export const notifyError = ({
  message = 'Error',
  description
}: {
  message?: string
  description: string
}) => {
  notification.error({
    ...commonOptions,
    message,
    description
  })
}

export const notifyGenericError = () =>
  notifyError({ message: 'Error', description: 'Something went wrong, please, try again later.' })
