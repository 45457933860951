import { useQuery } from '@vue/apollo-composable'
import gql from 'graphql-tag'
import type {
  GetPersonInstitutionsForSelectQuery,
  GetPersonInstitutionsForSelectQueryVariables
} from './getPersonInstitutionsForSelect.gql.generated'
import { computed } from 'vue'

const query = gql`
  query GetPersonInstitutionsForSelect($personId: ID!) {
    person(id: $personId) {
      id
      institutionMemberships {
        id
        institution {
          value: id
          label: name
        }
      }
    }
  }
`

export const getPersonInstitutionsForSelect = ({ personId }: { personId: string }) => {
  const { loading, result } = useQuery<
    GetPersonInstitutionsForSelectQuery,
    GetPersonInstitutionsForSelectQueryVariables
  >(query, { personId })

  const institutions = computed(
    () =>
      result.value?.person?.institutionMemberships.flatMap(
        (membership) => membership.institution
      ) ?? []
  )

  return { loading, institutions }
}
