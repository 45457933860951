<template>
  <Collapse :when="expanded && firstTickAfterFirstRenderPassed" v-if="firstRenderDone">
    <slot />
  </Collapse>
</template>

<script setup lang="ts">
import { nextTick, ref, watch } from 'vue'
import { Collapse } from 'vue-collapsed'

interface Props {
  expanded: boolean
  lazy?: boolean
}

const props = withDefaults(defineProps<Props>(), { lazy: true })

// State
const firstRenderDone = ref<boolean>(props.expanded || !props.lazy)
const firstTickAfterFirstRenderPassed = ref<boolean>(firstRenderDone.value)

// On first activation set the firstRenderDone flag to true
watch(props, () => {
  if (!firstRenderDone.value && props.expanded) {
    firstRenderDone.value = true

    nextTick(() => {
      firstTickAfterFirstRenderPassed.value = true
    })
  }
})
</script>
