import type {
  GetAllRelationshipTypesForGumRelationshipTypeSelectQuery,
  GetAllRelationshipTypesForGumRelationshipTypeSelectQueryVariables
} from './getAllRelationshipTypes.gql.generated'
import { useQuery } from '@vue/apollo-composable'
import gql from 'graphql-tag'
import { computed } from 'vue'

const FETCH_SIZE = 500

const query = gql`
  query GetAllRelationshipTypesForGumRelationshipTypeSelect($items: Int) {
    relationshipTypes(pagination: { items: $items }) {
      collection {
        value: id
        label: name
      }
      meta {
        pages
      }
    }
  }
`

export const getAllRelationshipTypes = () => {
  const { loading, result } = useQuery<
    GetAllRelationshipTypesForGumRelationshipTypeSelectQuery,
    GetAllRelationshipTypesForGumRelationshipTypeSelectQueryVariables
  >(query, { items: FETCH_SIZE })

  const relationshipTypes = computed(() => result.value?.relationshipTypes.collection || [])

  return { loading, relationshipTypes }
}
