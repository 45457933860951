import type {
  GetAllCostCentersForSelectQuery,
  GetAllCostCentersForSelectQueryVariables
} from './getAllCostCenters.gql.generated'
import { useQuery } from '@vue/apollo-composable'
import gql from 'graphql-tag'
import { computed, watch } from 'vue'

const query = gql`
  query GetAllCostCentersForSelect {
    costCenters(pagination: { items: 500 }) {
      collection {
        value: id
        label: name
      }
      meta {
        pages
      }
    }
  }
`

export const getAllCostCenters = () => {
  const { result, loading } = useQuery<
    GetAllCostCentersForSelectQuery,
    GetAllCostCentersForSelectQueryVariables
  >(query, {}, { fetchPolicy: 'cache-and-network' })

  const meta = computed(() => result.value?.costCenters.meta)
  const costCenters = computed(() => result.value?.costCenters.collection || [])

  watch(meta, () => {
    if (meta.value?.pages && meta.value.pages > 1) {
      throw "There are more than 500 cost centers and we're not fetching all of them"
    }
  })

  return { loading, costCenters }
}
