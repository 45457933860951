import type {
  GetEventNameForSelectQuery,
  GetEventNameForSelectQueryVariables
} from './getEventName.gql.generated'
import { useLazyQuery } from '@vue/apollo-composable'
import gql from 'graphql-tag'
import { computed } from 'vue'

const query = gql`
  query GetEventNameForSelect($id: ID!) {
    event(id: $id) {
      value: id
      label: name
    }
  }
`

export const getEventName = () => {
  const { result, load, loading } = useLazyQuery<
    GetEventNameForSelectQuery,
    GetEventNameForSelectQueryVariables
  >(query)

  const event = computed(() => result.value?.event)

  return { load, loading, event }
}
