import type {
  GetPersonNameForSelectQuery,
  GetPersonNameForSelectQueryVariables
} from './getPersonName.gql.generated'
import { useLazyQuery } from '@vue/apollo-composable'
import gql from 'graphql-tag'
import { computed } from 'vue'

const query = gql`
  query GetPersonNameForSelect($id: ID!) {
    person(id: $id) {
      value: id
      label: displayName
    }
  }
`

export const getPersonName = () => {
  const { result, load, loading } = useLazyQuery<
    GetPersonNameForSelectQuery,
    GetPersonNameForSelectQueryVariables
  >(query)

  const person = computed(() => result.value?.person)

  return { load, loading, person }
}
