import type {
  GetParticipantsForSelectQuery,
  GetParticipantsForSelectQueryVariables
} from './searchParticipations.gql.generated'
import { useQuery } from '@vue/apollo-composable'
import gql from 'graphql-tag'
import { computed } from 'vue'

const query = gql`
  query GetParticipantsForSelect($search: String, $eventId: ID!) {
    eventParticipationsForList(search: $search, eventId: $eventId) {
      collection {
        id
        person {
          id
          displayName
        }
      }
    }
  }
`

export const searchParticipations = (vars: { eventId: string; search?: string }) => {
  const { loading, result, refetch } = useQuery<
    GetParticipantsForSelectQuery,
    GetParticipantsForSelectQueryVariables
  >(query, vars)

  const participations = computed(() =>
    (result.value?.eventParticipationsForList.collection || []).map((p) => ({
      value: p.id,
      label: p.person.displayName
    }))
  )

  return { loading, participations, refetch }
}
