<template>
  <!-- We support only `top` placement as we don't have the arrow styles for the other placements -->
  <AntTooltip
    :open="disabled ? false : undefined"
    :trigger="trigger"
    :title="title"
    :mouseEnterDelay="delayAmount"
    :placement="placement"
  >
    <template #title v-if="$slots.title"><slot name="title" /></template>
    <slot />
  </AntTooltip>
</template>

<script setup lang="ts">
import AntTooltip from 'ant-design-vue/es/tooltip'
import { computed } from 'vue'

type Props = {
  disabled?: boolean
  trigger?: 'hover' | 'click'
  title?: string
  delayed?: boolean | number
  placement?:
    | 'top'
    | 'bottom'
    | 'left'
    | 'right'
    | 'topLeft'
    | 'topRight'
    | 'bottomLeft'
    | 'bottomRight'
    | 'leftTop'
    | 'leftBottom'
    | 'rightTop'
    | 'rightBottom'
}

const props = withDefaults(defineProps<Props>(), {
  disabled: false,
  trigger: 'hover',
  delayed: false,
  placement: 'top'
})

const delayAmount = computed(() => {
  if (!props.delayed) return 0.5

  if (typeof props.delayed === 'number') return props.delayed

  return 2
})
</script>

<style lang="less">
.ant-tooltip-inner {
  @apply bg-opacity-88 bg-black;
  @apply rounded-md;
  @apply p-2;
  @apply text-sm;
  @apply shadow-none;
}

.ant-tooltip-arrow {
  @apply w-4 h-4;
  bottom: -7px;
}

.ant-tooltip-arrow-content {
  @apply bg-opacity-88 bg-black;
  @apply w-4 h-4;
  @apply !shadow-none;
}

.ant-tooltip-placement-top {
  .ant-tooltip-arrow-content {
    transform: translateY(-8px) rotate(45deg);
  }
}
</style>
