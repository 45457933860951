import type {
  GetFormsForSelectQuery,
  GetFormsForSelectQueryVariables
} from './getForms.gql.generated'
import { useQuery } from '@vue/apollo-composable'
import gql from 'graphql-tag'
import { computed } from 'vue'

const query = gql`
  query getFormsForSelect($eventId: ID!) {
    forms(eventId: $eventId) {
      collection {
        value: id
        label: name
      }
    }
  }
`

export const getForms = (eventId: string) => {
  const { result, loading, refetch } = useQuery<
    GetFormsForSelectQuery,
    GetFormsForSelectQueryVariables
  >(query, { eventId })

  const forms = computed(() => result.value?.forms?.collection || [])

  return { forms, loading, refetch }
}
