import type {
  GetAllAffiliationTypesQuery,
  GetAllAffiliationTypesQueryVariables
} from './getAllAffiliationTypes.gql.generated'
import { useQuery } from '@vue/apollo-composable'
import gql from 'graphql-tag'
import { computed, watch } from 'vue'

const query = gql`
  query GetAllAffiliationTypes {
    affiliationTypes(pagination: { items: 500 }) {
      collection {
        value: id
        label: name
        wjcSpecific
      }
      meta {
        total
      }
    }
  }
`

export const getAllAffiliationTypes = () => {
  const { result, loading } = useQuery<
    GetAllAffiliationTypesQuery,
    GetAllAffiliationTypesQueryVariables
  >(query)

  const affiliationTypes = computed(() => result.value?.affiliationTypes.collection || [])
  const total = computed(() => result.value?.affiliationTypes.meta.total)

  watch(total, () => {
    if (total.value && total.value > 500) {
      throw 'There are more than 500 Affiliation types'
    }
  })

  return { loading, affiliationTypes }
}
