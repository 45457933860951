import { icon } from './icons'
import './styles.less'

if (typeof window.Redactor === 'undefined') {
  throw new Error('Redactor not found')
}

window.Redactor.add('plugin', 'conditions', {
  // Default settings
  defaults: {
    conditions: []
  },

  init() {
    this.app.toolbar.add('conditions', {
      title: 'Conditions',
      icon,
      command: 'conditions.popup'
    })
  },

  popup(e, button) {
    this.app.dropdown.create('conditions', { items: this._items() })
    this.app.dropdown.open(e, button)
  },

  noop() {
    // Do nothing
  },

  set(_event, button) {
    const currentSelection = this.app.block.get()

    // If not block is selected we can't insert the condition
    // Select the first block in the editor
    if (currentSelection === false) {
      const blocks = this.app.blocks.get()

      if (blocks.nodes.length === 0) {
        alert('Please select a location to insert the block')
        return
      }

      this.app.block.set(blocks.nodes[0])
    }

    const conditionName = button.name

    const instance = this.app.create('block.wrapper')
    const element = instance.element.nodes[0]
    element.setAttribute('data-condition', conditionName)
    element.classList.add('gum-condition', `gum-condition-[${conditionName}]`)
    this.app.block.insert({ instance })
  },

  _items() {
    if (
      !this.opts.is('conditions.conditions') ||
      !this.opts.get('conditions.conditions') ||
      this.opts.get('conditions.conditions').length === 0
    ) {
      return [{ title: 'No conditions are available', name: 'no-conditions', command: 'noop' }]
    }

    return this.opts.get('conditions.conditions').map((condition) => ({
      title: condition,
      name: condition,
      command: 'conditions.set'
    }))
  }
})
