import { useQuery } from '@vue/apollo-composable'
import gql from 'graphql-tag'
import type {
  GetCallingCodesQuery,
  GetCallingCodesQueryVariables
} from './getCallingCodes.gql.generated'
import { computed } from 'vue'

export const query = gql`
  query GetCallingCodes {
    countries(filters: { havingCallingCodes: true }, pagination: { items: 500 }) {
      collection {
        id
        name
        callingCodes
      }
    }
  }
`

export type CallingCode = {
  countryName: string
  callingCode: string
}

export const getCallingCodes = () => {
  const { loading, result } = useQuery<GetCallingCodesQuery, GetCallingCodesQueryVariables>(query)

  const callingCodes = computed<CallingCode[]>(() => {
    if (!result.value?.countries.collection) {
      return []
    }

    return result.value.countries.collection.flatMap((country) =>
      country.callingCodes.map((callingCode) => ({
        countryName: country.name,
        callingCode: callingCode
      }))
    )
  })

  return { loading, callingCodes }
}
