import type {
  GetAllEventVotingGroupsQuery,
  GetAllEventVotingGroupsQueryVariables
} from './getAllEventVotingGroups.gql.generated'
import { useQuery } from '@vue/apollo-composable'
import gql from 'graphql-tag'
import { computed } from 'vue'

const query = gql`
  query GetAllEventVotingGroups($eventId: ID!) {
    event(id: $eventId) {
      id
      votingGroups {
        value: id
        label: name
        options: keys {
          value: id
          label: name
        }
      }
    }
  }
`

export const getEventVotingGroups = (eventId: string) => {
  const { loading, result } = useQuery<
    GetAllEventVotingGroupsQuery,
    GetAllEventVotingGroupsQueryVariables
  >(query, { eventId })

  const votingGroups = computed(() => result.value?.event?.votingGroups || [])

  return { loading, votingGroups }
}
