import type {
  GetAllTagsForSelectQuery,
  GetAllTagsForSelectQueryVariables
} from './getAllTags.gql.generated'
import { useQuery } from '@vue/apollo-composable'
import gql from 'graphql-tag'
import { computed } from 'vue'

const FETCH_SIZE = 1000

const query = gql`
  query GetAllTagsForSelect($items: Int) {
    tags(pagination: { items: $items }) {
      collection {
        id
        value: name
        label: name
      }
      meta {
        pages
        total
      }
    }
  }
`

export const getAllTags = () => {
  const { loading, result } = useQuery<GetAllTagsForSelectQuery, GetAllTagsForSelectQueryVariables>(
    query,
    { items: FETCH_SIZE }
  )

  const tags = computed(() => result.value?.tags.collection || [])
  const total = computed(() => result.value?.tags.meta.total || 0)

  if (total.value > FETCH_SIZE) {
    console.error(`There are ${total.value} tags and we're fetching just ${FETCH_SIZE}`)
  }

  return { loading, tags }
}
