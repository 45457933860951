<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    :class="rotations[props.direction]"
  >
    <path
      d="M6.47003 4L5.53003 4.94L8.58336 8L5.53003 11.06L6.47003 12L10.47 8L6.47003 4Z"
      fill="black"
      :fill-opacity="opacities[props.colorIntensity]"
    />
  </svg>
</template>

<script setup lang="ts">
interface Props {
  direction?: 'left' | 'right' | 'down' | 'up'
  colorIntensity?: 'light' | 'dark'
}

const rotations: { [key in NonNullable<Props['direction']>]: string } = {
  left: 'rotate-180',
  right: '',
  down: 'rotate-90',
  up: '-rotate-90'
}

const opacities: { [key in NonNullable<Props['colorIntensity']>]: string } = {
  light: '0.54',
  dark: '0.88'
}

const props = withDefaults(defineProps<Props>(), {
  direction: 'right',
  colorIntensity: 'dark'
})
</script>
