<template>
  <TypographyTitle :level="props.level" :type="props.type">
    <slot />
  </TypographyTitle>
</template>

<script lang="ts" setup>
import { TypographyTitle } from 'ant-design-vue/es/components'

interface Props {
  level?: 1 | 2 | 3 | 4 | 5
  type?: 'secondary' | 'success' | 'warning' | 'danger'
}

const props = withDefaults(defineProps<Props>(), {
  level: 1
})
</script>

<style scoped lang="less">
.ant-typography {
  @apply flex items-center gap-2;
}

h1,
h2,
h3,
h4,
h5 {
  &.ant-typography {
    @apply font-semibold m-0;
  }
}
</style>
