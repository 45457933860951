<template>
  <div class="bg-white p-6 rounded w-full">
    <button
      @click="toggleActive"
      class="flex items-center justify-between w-full bg-transparent border-none p-0"
      :class="{ 'cursor-default': !collapsible, 'cursor-pointer': collapsible }"
      :disabled="!collapsible"
    >
      <TextTitle :level="4">
        <slot name="title" />
      </TextTitle>

      <ArrowIcon v-if="collapsible" class="w-6 h-6" :direction="active ? 'up' : 'down'" />
    </button>

    <GumCollapse :expanded="!collapsible || active">
      <slot />
    </GumCollapse>
  </div>
</template>

<script setup lang="ts">
import { ref, watch } from 'vue'
import { ArrowIcon, TextTitle } from '.'
import GumCollapse from './collapsible/GumCollapse.vue'

// Props & Events
const props = withDefaults(
  defineProps<{
    expanded?: boolean
    collapsible?: boolean
  }>(),
  { collapsible: false, expanded: false }
)
const emits = defineEmits<{ (e: 'update:expanded', expanded: boolean): void }>()

//
// Behavior
//
watch(props, ({ expanded }) => (active.value = expanded))

//
// State
//
const active = ref<boolean>(props.expanded)

//
// Handlers
//
const toggleActive = () => {
  active.value = !active.value

  emits('update:expanded', active.value)
}
</script>
