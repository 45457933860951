import type { MeQuery, MeQueryVariables } from './getMe.gql.generated'
import { useQuery } from '@vue/apollo-composable'
import gql from 'graphql-tag'
import { computed } from 'vue'

const query = gql`
  query Me {
    me {
      id
      displayName
      releaseNoteLastVisit
    }
  }
`

export const getMe = () => {
  const { loading, result, refetch } = useQuery<MeQuery, MeQueryVariables>(query)

  const me = computed(() => result.value?.me)

  return { loading, me, refetch }
}
